<template>
  <vue-three-sixty
      :amount="amount"
      :imagePath="path"
      disableZoom
      :fileName="file"
  >
  </vue-three-sixty>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: ''
    },
    amount: {
      type: Number,
      default: 0
    },
    file: {
      type: String,
      default: ''
    }
  },
  mounted() {
    // console.log(this.path, this.amount, this.file)
  }
}
</script>